import React from 'react'
import background from '../images/background.jpg'
import sliderImg from '../images/slider-img.png'

import { Link } from 'react-router-dom'

export const Slider = () => {
const backgroundSlider = {
backgroundImage: `url(${background})`,
backgroundSize: 'cover',
backgroundRepeat: 'no-repeat',
backgroundPosition: 'top',
height: '100vh',
display:'flex',
alignItems:'center'
};

const bannerBtn={
padding: '2px 3rem',
margin: '2rem',
height: '58px',
}
return (
<>
    <section style={backgroundSlider} className="home-slider">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5 d-flex align-items-center">
                    <div className='slider-content'>
                        <h1 className="title">PLAY, CREATE & EARN</h1>
                        <p className="sub-title">"With NFTspace you discover and represent the collection of non fungible tokens (NFTs) NFT Space is the world where you can create, own, and monetize your assets and virtual experiences."</p>
                        <div className="wrap-btn">
                            <button className="tf-button-st2 btn-effect bannerBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                <span className="effect"> connect wallet</span>
                            </button>
                            <Link to='/live_drop' className="tf-button btn-effect mint-cta" style={{ padding: '12px 4rem', border: '2px solid #fff' , marginLeft: '2rem' , height: '60px' }}>
                                <span className="boder-fade"></span>
                                <span className="effect">MINT</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6  h-100">
                    <div className="marque-img">
                        <marquee direction="right" behavior="alternate">
                            <img className="img-fluid" src={sliderImg} />
                        </marquee>
                    </div>
                </div>
            </div>
        </div>
    </section>
</>
)
}