import React from 'react'


export const Contact = () => {
    return (
        <>
            <section className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h3>Contact Us</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-12"></div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="content-about m-b50 mobie-40">
                                <div className="tf-title st2 m-b17">
                                    <h4 className="title text-center">Have a question </h4>
                                </div>
                                <p className="m-r-40 text-center">WRITE TO US </p>
                            </div>
                            <form action="contact/contact-process.php" className="form-contact" id="contactform">
                                <fieldset><input type="text" name="name" id="name" placeholder="Name" /></fieldset>
                                <fieldset>
                                    <input type="email" name="mail" id="mail" placeholder="Email Address" />
                                </fieldset>
                                <fieldset>
                                    <input type="number" name="phone" id="phone" placeholder="Phone" /></fieldset>
                                <fieldset><textarea placeholder="Type your Messege" rows="5" tabindex="4" name="message"
                                    className="message" id="message"></textarea></fieldset><button className="tf-button btn-effect"
                                        type="submit"><span className="boder-fade"></span><span className="effect">Send
                                            Message</span></button>
                            </form>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12"></div>
                    </div>
                </div>
            </section>
        </>
    )
}