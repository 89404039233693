import { ApiConfig } from '../apiConfig/ApiConfig';
import { ApiCallPost } from '../apiConfig/ApiCall';
import { ApiCallGET } from '../apiConfig/ApiCall';
import { ConsoleLogs } from '../../utils/ConsoleLogs';

const TAG = 'AuthService';

const AuthService = {

    login: async (email, password) => {
        const { baseUrl, login } = ApiConfig;
        const url = baseUrl + login;
        const params = {
            email: email,
            password: password,

        };
        ConsoleLogs('login', `url: + ${url}`);
        ConsoleLogs(
            'login', `loginRequestParms : ' + ${JSON.stringify(params)}`,
        );
        const headers = {
            'Content-Type': 'application/json',
        };
        return ApiCallPost(url, params, headers);
    },



    register: async (username,number,email,otp,password,referralcode) => {
        const { baseUrl, register } = ApiConfig;
    
        const url = baseUrl + register;
    
        const params = {
          username: username,
          number: number,
          email: email,
          otp: otp,
          password: password,          
          referralcode: referralcode,          
         
        };
    
        ConsoleLogs(TAG + ', login', `url : ' + ${url}`);
        ConsoleLogs(
          TAG + ', login',
          `loginRequestParams : ' + ${JSON.stringify(params)}`,
        );
    
        const headers = {
          'Content-Type': 'application/json',
        };
    
        return ApiCallPost(url, params, headers);
      },

    otpVerify: async (email,typ='') => {
      const { baseUrl, otpVerify } = ApiConfig;
  
      const url = baseUrl + otpVerify;
  
      const params = {
        signId: email,   
        type:typ    
      };
  
      ConsoleLogs(TAG + ', otpVerify', `url : ' + ${url}`);
      ConsoleLogs(
        TAG + ', otpVerify',
        `loginRequestParams : ' + ${JSON.stringify(params)}`,
      );
  
      const headers = {
        'Content-Type': 'application/json',
      };
  
      return ApiCallPost(url, params, headers);
    },

    forgotPassword: async (email) => {
      const { baseUrl, forgotpassword } = ApiConfig;
  
      const url = baseUrl + forgotpassword;
  
      const params = {
        email: email,
      };
  
      ConsoleLogs(TAG + ', forgotpassword', `url : ' + ${url}`);
      ConsoleLogs(
        TAG + ', forgotpassword',
        `loginRequestParams : ' + ${JSON.stringify(params)}`,
      );
  
      const headers = {
        'Content-Type': 'application/json',
      };
  
      return ApiCallPost(url, params, headers);
    },

    resetPassword: async (newPassword, rePassword, id) => {
      const { baseUrl, resetPassword } = ApiConfig;
  
      const url = baseUrl + resetPassword;
  
      const params = {
        newpass: newPassword,
        confpass: rePassword,
        hex: id,
      };
  
      ConsoleLogs(TAG + ', resetPassword', `url : ' + ${url}`);
      ConsoleLogs(
        TAG + ', forgotpassword',
        `loginRequestParams : ' + ${JSON.stringify(params)}`,
      );
  
      const headers = {
        'Content-Type': 'application/json',
      };
  
      return ApiCallPost(url, params, headers);
    },

    verifyId: async (id) => {
      const { baseUrl, verifyId } = ApiConfig;
  
      const url = baseUrl + verifyId;
  
      const params = {
        hex: id,
      };
  
      ConsoleLogs(TAG + ', verifyId', `url : ' + ${url}`);
      ConsoleLogs(
        TAG + ', verifyId',
        `loginRequestParams : ' + ${JSON.stringify(params)}`,
      );
  
      const headers = {
        'Content-Type': 'application/json',
      };
  
      return ApiCallPost(url, params, headers);
    },

    


    

    // getdata: async () => {
    //     const token = localStorage.getItem('token');
    //     const { baseData, getdata } = ApiConfig;
    //     const url = baseData + getdata;
    //     ConsoleLogs('getdata', `url: + ${url}`);
    //     const headers = {
    //         'token': 'application/json',
    //         "Authorization": 'Bearer'+ ' ' +token,
    //     };
    //     return ApiCallGET(url, headers);
    // },

}

export default AuthService;
