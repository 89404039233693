import React, {useEffect, useState} from "react";
import HomeService from "../api/services/HomeService";
// import { useNavigate } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from "../CustomAlertMessage";
import { Sidebar } from './Sidebar';

export default function Wallet() {
    const [price, setPrice] = useState([]);
    const [token, setToken] = useState('');
    const [amount, setAmount] = useState('');
    const [usdtBal, setUsdtBal] = useState('');
    // const history = useHistory();

    useEffect(() => {
        handlePrice();
        handleUsdtBal();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePrice = async () => {
    //   LoaderHelper.loaderStatus(true);
        await HomeService.getPrice().then(async result => {
          console.log(result.data, 'getPrice');
          if (result.success) {
            try {
            //   LoaderHelper.loaderStatus(false);
                setPrice(parseFloat(result.resp) );
                
            } catch (error) {
            //   LoaderHelper.loaderStatus(false);
              alertErrorMessage(error);
              //console.log('error', `${error}`);
            }
          } else {
            // LoaderHelper.loaderStatus(false);
            // alertErrorMessage(result.message);
          }
        });
      };

      const handleUsdtBal = async () => {
        // LoaderHelper.loaderStatus(true);
        await HomeService.getUsdtBal().then(async result => {
          console.log(result.data, 'getUsdtBal');
          if (result.success) {
            try {
            //   LoaderHelper.loaderStatus(false);
                setUsdtBal(result.data);
                
            } catch (error) {
            //   LoaderHelper.loaderStatus(false);
              alertErrorMessage(error);
              console.log('error', `${error}`);
            }
          } else {
            // LoaderHelper.loaderStatus(false);
            // alertErrorMessage(result.message);
          }
        });
      };

      const handleBuyCoin = async (amount) => {
        // LoaderHelper.loaderStatus(true);
        await HomeService.getBuyCoin(amount).then(async result => {
          console.log(result, 'getBuyCoin');
          if (result.success) {
            try {
            //   LoaderHelper.loaderStatus(false);
                alertSuccessMessage("SuccessFull");
                handleUsdtBal();
                setToken("");
                setAmount("");
            } catch (error) {
            //   LoaderHelper.loaderStatus(false);
              alertErrorMessage(error);
              //console.log('error', `${error}`);
            }
          } else {
            // LoaderHelper.loaderStatus(false);
             alertErrorMessage(result.message);
          }
        });
      };

      const handleInputChange = (event) => {
          switch(event.target.name) {
              case "usdt":
                  setAmount(event.target.value);
                  let myToken = parseFloat(event.target.value);
                  setToken((myToken/price)|| 0);
                  break;

              default:
                break;
            
          }

      }
    return (
        <>
            <Sidebar />
            <div className="container" id="main">
                <div className="row mt-5">
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6">
                      <h5 className='text-center'>Wallet</h5>
                        <div className=" mt-4 box-text corner-box active">
                            <p className='dashbord-subtitle'>Buy Token</p>
                            <hr />
                            <form className="form-contact" id="contactform">
                                <fieldset>
                                    <p>Total Price (USDT)</p>
                                    <input type="text" name="usdt" id="name" placeholder="Enter Total Price" value={amount} onChange={handleInputChange}/>
                                </fieldset>
                                <p>USDT Balance:- {usdtBal}</p>
                                <fieldset className='mt-4'>
                                    <p>Token Qty</p>
                                    <input type="text" name="nationality" id="nationality" placeholder="Enter Token Qty" value={token}/>
                                </fieldset>
                                {/* <div className='nfs-conv'>
                                    <p>1 NFS = {price}</p>
                                </div>   */}
                                <button className="tf-button btn-effect update-btn" type="button" onClick={() => handleBuyCoin(amount)}>
                                    <span className="boder-fade"></span>
                                    <span className="effect">BUY</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
