import React from 'react'

export const Faq = () => {
    return (
        <>

            <section className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h3>LOOKING FOR ANSWERS?</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section faq style3 bg-st2 flat-accordion">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="accordion" id="accordionExample">

                                <div className="accordion-item box-text corner-box active">
                                    <div className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed h7 toggle-title active" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                            <h2 className="h7">What is NFT?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <hr />
                                            <p>A NFT or non-fungible token is a digital asset. NFTs are
                                                unique i.e one is not equal to another.</p>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingSeven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                            <h2 className="h7">Why buy NFTs/What makes them valuable?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>There are a ton of reasons to buy these items/NFTs.</p>
                                            <ul className="nft-features-list">
                                                <li> Unique - Each NFT is unique, it's only one of its kind.</li>
                                                <li> Copyrights - Your NFT might come with the copyrights to the asset if the seller
                                                    chooses to transfer them.</li>
                                                <li>Rare - Since they are unique and cannot be copied, they are rare.</li>
                                                <li>Immutable - No one can remove or edit the digital asset this means it can never
                                                    change, removed or taken down off the blockchain.</li>
                                                <li>Forever - NFTs are forever. As the data always exists on the blockchain.</li>
                                                <li>Resellable - You can always resell your NFT.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingEight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                            <h2 className="h7"> What is the NFTSpace Marketplace?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>The NFTSpace marketplace features all forms of digital
                                                artworks and collectibles. The NFTSpace marketplace provides the community and liquidity
                                                for users to launch and trade NFTs.</p>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingNine">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                            <h2 className="h7"> What is the NFTSpace Marketplace?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseNine" className="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>The NFTSpace marketplace features all forms of digital
                                                artworks and collectibles. The NFTSpace marketplace provides the community and liquidity
                                                for users to launch and trade NFTs.</p>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingTen">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                            <h2 className="h7">How to find any NFT Contract Address?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseTen" className="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>NFTSpace supports ERC-721 tokens. You can check the contract
                                                address of your assets on relevant NFT platforms, or on your Wallet’s Etherscan page
                                                under ERC-721 tokens.</p>
                                        </div>
                                    </div>
                                </div>
                                <br/>

                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingEleven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                            <h2 className="h7">How to Create your own NFT on NFTSpace?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseEleven" className="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>With few steps you canconvert your digital art to  NFTs and they are ready to trade. You can also deposit and withdraw the NFTs to a compatible wallet or another exchange.</p>
                                        </div>
                                    </div>
                                </div>
                                <br/>


                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingTwelve">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                            <h2 className="h7">How to Buy an NFT on NFTSpace Marketplace?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseTwelve" className="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>You can now buy NFTs on NFTSpace Marketplace using a fixed price, by making an offer to the seller, or bidding in an NFT auction. NFT purchases comes with avery simple fee structure to support creators and depositors. To purchase an NFT, you’re first of all going to need a NFTSpace or metamask account.</p>
                                        </div>
                                    </div>
                                </div>
                                <br/>


                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingThirteen">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                            <h2 className="h7">How to Sell an NFT on NFTSpace Marketplace?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseThirteen" className="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>You can sell your NFTs NFTSpace Marketplace using a fixed price,bids and  Auctions  You can also accept payments in several different cryptocurrencies.
                                        If you submited your digital art for creating NFT collection, The NFTSpace team will review your digital art before listing it on NFTSpace Marketplace.If you want to sell your NFT listed on any other platform you are free to sell.</p>
                                        </div>
                                    </div>
                                </div>
                                <br/>


                                <div className="accordion-item box-text corner-box">
                                    <div className="accordion-header" id="flush-headingFourteen">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                            <h2 className="h7">Who is a NFT Creator?</h2>
                                        </button>
                                    </div>
                                    <div id="flush-collapseFourteen" className="accordion-collapse collapse" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <p>NFT Creators are those who creates the digital art and convert/mint them to NFTs. If the creatos art is royalty based then the creators also receive a % of royalty payment from all subsequent trading.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="content-about p-l70 m-b50 mobie-40">
                                <div className="tf-title st2 m-b17">
                                    <h4 className="title">Have a question </h4>
                                </div>
                                <p className="m-r-40" style={{ color: "#14C2A3" }}>Write to us</p>
                            </div>
                            <form action="contact/contact-process.php" className="form-contact p-l70" id="contactform">
                                <fieldset>
                                    <input type="text" name="name" id="name" placeholder="Name" />
                                </fieldset>
                                <fieldset>
                                    <input type="email" name="mail" id="mail" placeholder="Email Address" />
                                </fieldset>
                                <fieldset>
                                    <input type="number" name="phone" id="phone" placeholder="Phone" />
                                </fieldset>
                                <fieldset>
                                    <textarea placeholder="Type your Messege" rows="5" tabIndex="4" name="message" className="message" id="message"></textarea>
                                </fieldset>
                                <button className="tf-button btn-effect submit-btn" type="submit"><span className="boder-fade"></span><span className="effect">Send Message</span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
