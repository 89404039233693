import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";

// import $ from "jquery";

// $(document).ready(function () {
//   $(".sidbar-dropdown").click(function () {
//     $("#dropdownItem").toggle();
//   });
// });

export const Sidebar = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const [addClass, setAddClass] = useState(false);

  const handleClick = () => {
    setAddClass(!addClass);
  };
  return (
    <>
      <div className="dashboard-header">
        <div className="container-fluid d-flex">
          <button
            id="openNav"
            className="btn sidebar-cta"
            onClick={handleClick}
          >
            &#9776;
          </button>
          {/* <img className="img-fluid logo mx-5" src={process.env.PUBLIC_URL + "/assets/images/logo/logo-new.png"}/> */}
          <h1
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              color: "#14C2A3",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            NFS Token
          </h1>
        </div>
      </div>
      <div className={`sidebar ${addClass ? "show" : ""}`}>
        <ul className="siderbarElement">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/myaccount">My Account</Link>
          </li>
          <li>
            <Link to="/wallet">Buy Token</Link>
          </li>
          <li>
            <Link to="/token-withdraw">Withdraw</Link>
          </li>
          <li>
            <Link to="/deposit">Deposit</Link>
          </li>
          <li>
            <Link to="/transaction">Transaction</Link>
          </li>
          <li>
            <Link to="/refferal">Referal List</Link>
          </li>
          <li>
            <div
              className="tf-button-st2 dashboard-btn btn-effect"
              type="button"
            >
              <span className="effect" onClick={handleLogOut}>
                Log Out
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
