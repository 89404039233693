import React from 'react'
import { Link } from 'react-router-dom'
export const Comingsoon = () => {
    return (
        <>            
            <div id="wrapper">
                <section className="page-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumbs">
                                    <h3>Coming Soon</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tf-section tf-about" id="metaverse">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="content-about m-b60 mobie-40">
                                <div className="tf-title">
                                    {/*<p className="h8 sub-title">About us</p>*/}
                                    <h4 className="title m-b17">Metaverse</h4>
                                </div>
                                {/* <p className="pd30 center">Maecenas sit pretium, cras in. In quisque sem id eget. In vel gravida ut
                                    adipiscing integer felis.</p> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-xl-10 col-lg-10 col-md-10 mt-5">
                                <div className="box-text corner-box tabl-mb-30 active">
                                    <div className="h7">Play with your friends in The NFTSpace Metaverse.</div>
                                    <p>Metaverse has become the recent treand in crypto world with tech giants ensuring to enhance
                                        our digital lives. The idea of a huge virtual online world certainly sounds very exciting,
                                        and hundreds of companies including Microsoft, Meta Inc (formerly Facebook Inc), Google,
                                        etc., are already betting on the potential that the metaverse holds.NFTSpace is coming up
                                        with an NFT Metaverse where you can play with NFTSpace NFTs and earn rewards in NFS (native
                                        token of NFTSpace).</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-xl-10 col-lg-10 col-md-12 mt-5">
                                <div className="box-text corner-box tabl-mb-30">
                                    <div className="h7">Not a user, you are Avatar</div>
                                    <p>Metaverse users are not called as users they are called ‘Avatars’. An avatar in the Metaverse is unique with limitless possibilities it is not just a user created face. It is your identity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-xl-10 col-lg-10 col-md-12 mt-5">
                                <div className="box-text corner-box mb-0">
                                    <div className="h7">Metaverse is a New Word</div>
                                    <p>Metaverse is a new world with endless possibilities The possibilities which allow people
                                        to achieve things that may not be easy or possible in the physical world. Just imagine
                                        connecting with the world by simply staying at one place without any hassle of movement
                                        or spending money to fly to that destination. Not just this in nearing future people may
                                        have jobs in the metaverse and there will be a new revenue system within Metacerse itself.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>                        
                </section>

                <section className="tf-section tf-team bg-st1 mt-5" id="marketplace">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-12">
                                <div className="tf-title st2 m-b60 aos-init aos-animate">
                                    <h4 className="title m-b17">Marketplace</h4>
                                    <h6 className="title m-b17 buy-nft-text">BUY NFTS , TRADE , PLAY AND EARN</h6>
                                    <p>NFTSpace marketplace enables digital artists to buy, sell and create the NFTs from any
                                        part of the world.
                                        Here you can search and buy creator's assets with NFS (native token of NFTSpace ) and
                                        also incorporate NFTSpace exculsive NFTs in NFTSpace upcoming Metaverce.
                                        NFTSpace is a community centered marketplace which will share rewards for using its
                                        marketplace or staking its token. NFTSpace offers similar selling points just like any
                                        other NFT marketplace for the users but with a community-based approach, NFTSpace
                                        platform intends to empower users and creators through NFTSpace native token NFS
                                        distribution in rewards. </p>
                                </div>

                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-12">
                                <div className="wrap-fx ml30-fx p-l70 aos-init aos-animate">

                                    <div className="box-text st2 corner-box mt-5">
                                        <div className="number">02</div>
                                        <div className="h7"> Gas-free Marketplace</div>
                                        <p>With our multiple blockchains support, create, buy, and sell NFTs with paying NEXT TO
                                            ZERO gas fee.</p>
                                    </div>
                                    <div className="box-text st2 mobie-mb-0 mobie-mb-30 corner-box mt-5 active">
                                        <div className="number">03</div>
                                        <div className="h7">Advertise on NFTSpace</div>
                                        <p>With the most powerful way for users to buy and sell NFTs, we offer the most advanced
                                            features.</p>
                                    </div>
                                    <div className="box-text st2 mobie-mb-0 corner-box mt-5">
                                        <div className="number">04</div>
                                        <div className="h7">Earn Commission on Per Sale!</div>
                                        <p>With over thousands of collections, we are proud to host the widest range of
                                            categories, ranging from digital to physical NFTs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="tf-section how-we-work" id="earn-nfs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tf-title st2 aos-init aos-animate">
                                    {/* <!-- <p className="h8 sub-title">How we work</p> --> */}
                                    <h4 className="title m-b17 text-center">Earn NFS</h4>
                                    <h6 className="title m-b17 buy-nft-text text-center">PLAY AND EARN WITH NFTSPACE </h6>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-2"></div>
                                <div className="col-xl-8 col-lg-s8 col-md-8 col-12">
                                    <p className="earn-p text-center">Interested in earning with NFTspace?We empower artists,
                                        creators, and players to to unleash their creativity and earn income. All NFTspace users
                                        will be
                                        elegable earn tokens with our different earning programs. Earn tokens through our
                                        upcomming earning platform that includes :</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-12 aos-init aos-animate mt-5">
                            <div className="box-text st2 corner-box earn-box">
                                <div className="number">01</div>
                                <div className="h7 text-center">PALY AND EARN</div>
                                <p className="earn-p">Play games on NFTSpace and earn rewards</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 aos-init aos-animate mt-5">
                            <div className="box-text st2 corner-box earn-box active">
                                <div className="number">02</div>
                                <div className="h7 text-center">REFER AND EARN</div>
                                <p className="earn-p">Refer NFTSpace play to earn platform to others and earn rewards on
                                    their rewards.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12 aos-init aos-animate mt-5">
                            <div className="box-text st2 mobie-mb-0 corner-box earn-box">
                                <div className="number">03</div>
                                <div className="h7 text-center">STAKE AND EARN</div>
                                <p className="earn-p">Stake NFS the native token of NFTSpace and earn rewards </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <h4 className="title m-b17 buy-nft-text text-center mt-4">Interested In participating in our Earn programe?</h4>
                            <div className="col-md-2"></div>
                            <div className="col-xl-8 col-lg-s8 col-md-8 col-12">
                                <p className="earn-p text-center">Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks and powers for NFTSpace users.
                                    <Link to="/contact" className="tf-button btn-effect">
                                        <span className="boder-fade"></span>
                                        <span className="effect">Contact Us</span>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </div >
    </>
  )
}
