import { ApiConfig } from '../apiConfig/ApiConfig';
import { ApiCallGET, ApiCallPost } from '../apiConfig/ApiCall';
import { ConsoleLogs } from '../../utils/ConsoleLogs';

const TAG = 'HomeService';

const HomeService = {

  getBalance: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, getBalance } = ApiConfig;

    const url = baseWallet + getBalance;

    const params = {};

    ConsoleLogs(TAG + ', getBalance', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getPrice: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, getPrice } = ApiConfig;

    const url = baseWallet + getPrice;

    const params = {};

    ConsoleLogs(TAG + ', getPrice', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getReferalData: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, getReferalData } = ApiConfig;

    const url = baseUrl + getReferalData;

    const params = {};

    ConsoleLogs(TAG + ', getReferalData', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getTransactions: async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const { baseWallet, getTransactions } = ApiConfig;

    const url = baseWallet + getTransactions;

    const params = {
      userId: userId,
    };

    ConsoleLogs(TAG + ', getReferalData', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getBuyCoin: async (amount) => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const { baseWallet, getBuyCoin } = ApiConfig;

    const url = baseWallet + getBuyCoin;

    const params = {
      userId: userId,
      amount: amount
    };

    ConsoleLogs(TAG + ', getReferalData', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },
  withdrawNfs: async (amount,otp,address) => {
    const token = localStorage.getItem("token");
    const { baseWallet, withdrawNfs } = ApiConfig;

    const url = baseWallet + withdrawNfs;

    const params = {
      amount,
      otp,
      address
    };

    ConsoleLogs(TAG + ', getReferalData', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },
  withdrawReferNfs: async (amount,otp,address) => {
    const token = localStorage.getItem("token");
    const { baseWallet, withdrawReferNfs } = ApiConfig;

    const url = baseWallet + withdrawReferNfs;

    const params = {
      amount,
      otp,
      address
    };

    ConsoleLogs(TAG + ', getReferalData', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getAccountDetails: async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const { baseUrl, getAccountDetails } = ApiConfig;

    const url = baseUrl + getAccountDetails;

    const params = {
      userId: userId,
    };

    ConsoleLogs(TAG + ', getAccountDetails', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getUserSelfie: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUrl, getUserSelfie } = ApiConfig;

    const url = baseUrl + getUserSelfie;


    ConsoleLogs(TAG + ', getAccountDetails', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };

    return ApiCallPost(url, formData, headers);
  },

  getUserDetails: async (firstName, lastName, email, country) => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const { baseUrl, getUserDetails } = ApiConfig;

    const url = baseUrl + getUserDetails;

    const params = {
      userId: userId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      country: country
    };

    ConsoleLogs(TAG + ', getUserDetails', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getUsdtBal: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, getUsdtBal } = ApiConfig;

    const url = baseWallet + getUsdtBal;

    const params = {};

    ConsoleLogs(TAG + ', getUsdtBal', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getNfsBal: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, getNfsBal } = ApiConfig;

    const url = baseWallet + getNfsBal;

    const params = {};

    ConsoleLogs(TAG + ', getUsdtBal', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallGET(url, headers);
  },

  getAddress: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, getAddress } = ApiConfig;

    const url = baseWallet + getAddress;

    const params = {};

    ConsoleLogs(TAG + ', getAddress', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getTokenSold: async () => {
    const token = localStorage.getItem("token");
    const { appUrl, getTokenSold } = ApiConfig;

    const url = appUrl + getTokenSold;

    const params = {};

    ConsoleLogs(TAG + ', getTokenSold', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  getCreatedAddr: async (usdtAddrss, trxAddress) => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const { baseWallet, getCreatedAddr } = ApiConfig;

    const url = baseWallet + getCreatedAddr;

    const params = {
      usdtAdr: usdtAddrss,
      tronAdr: trxAddress,
      userId: userId
    };

    ConsoleLogs(TAG + ', getCreatedAddr', `url : ' + ${url}`);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },
};

export default HomeService;
