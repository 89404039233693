import React, { useState } from "react";
import './style.css';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import { alertSuccessMessage, alertErrorMessage } from "../../CustomAlertMessage";
import { useNavigate } from "react-router-dom";
import AuthService from "../../api/services/AuthService";
import loginimg from '../../assets/images/login_bg.jpg';
// import LoaderHelper from "../Loading/LoaderHelper";


const Register = () => {

    const history = useNavigate();
    const ref = window.location.href.split("=")[1];
    const [username, setUsername] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [referralcode, setReferralcode] = useState(ref);
    const [isValid, setIsValid] = useState(false)


    //setReferralcode(ref);
    console.log(referralcode, "referal");
    console.log(ref, "ref");

    const stringValPatternValidation = stringVal => {
        return /\s/g.test(stringVal);
      };

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "username":
                setUsername(event.target.value);
                console.log(event.target.value);
                break;
            case "number":
                setNumber(event.target.value);
                console.log(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                console.log(event.target.value);
                break;
            case "otp":
                setOtp(event.target.value);
                console.log(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                console.log(event.target.value);
                stringValPatternValidation(event.target.value);
                setIsValid(stringValPatternValidation(event.target.value));
                break;
            case "referralcode":
                setReferralcode(event.target.value);
                console.log(event.target.value);
                break;
        }
    }

    const showPassword = () => {
        let pass = document.querySelector('input[name="password"]');
        let icon = document.querySelector("#list");
        console.log(pass.type);
        if (pass.type === "password") {
            icon.classList.add("show");
            pass.type = "text";
        } else {
            icon.classList.remove("show");
            pass.type = "password";
        }
    }


    const handleRegister = async (username, number, email, otp, password, referralcode) => {
        // LoaderHelper.loaderStatus(true);
        await AuthService.register(username, number, email, otp, password, referralcode).then(async result => {
            //console.log(result, 'loginD');
            if (result.message == "Registration Success") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                    history("/login");
                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    // alertErrorMessage(error);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });

    }

    const handleOtp = async (email) => {
        // LoaderHelper.loaderStatus(true);
        await AuthService.otpVerify(email).then(async result => {
            if (result.message === "OTP Sent.") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });

    }


      return (
        <section className="page_sec tf-section tf-contact" >
            <img className="login_bg" />
            <div className="container login-page" >
                <Row className="align-items-center no-gutter">
                    <Col lg={2} md={2} sm={12}>
                    </Col>

                    <Col lg={8} md={8} sm={12} className="col-xs-12 m-auto">
                        <div className="page-title p-4">
                            <p className="your-session">Register to NftSpace </p>
                            <form className="form-contact" id="mLoginForm">
                                <Row>
                                    <Col lg={6} md={6} sm={6} className="col-xs-12">
                                        <fieldset>
                                            <label className="mb-2">Enter User Name</label>
                                            <input type="text" name="username" placeholder="Enter User Name" value={username} onChange={handleInputChange} />
                                        </fieldset>

                                    </Col>
                                    <Col lg={6} md={6} sm={6} className="col-xs-12">
                                        <div className="otp_group" >
                                            <fieldset>
                                                <label className="mb-2">Enter Email</label>
                                                <button type="button" id="getotp" onClick={() => handleOtp(email)}>GET OTP</button>
                                                <input type="email" name="email" placeholder="Enter Email" value={email} onChange={handleInputChange} />
                                            </fieldset>

                                        </div>

                                    </Col>
                                    <Col lg={6} md={6} sm={6} className="col-xs-12">
                                        <fieldset>
                                            <label className="mb-2">Enter OTP</label>
                                            <input type="text" name="otp" placeholder="Email Otp" value={otp} onChange={handleInputChange} />
                                        </fieldset>

                                    </Col>
                                    <Col lg={6} md={6} sm={6} className="col-xs-12">
                                        <div className="view_pass" >
                                            <fieldset>
                                                <label className="mb-2">Enter Password
                                                    {/* <span className="fa fa-eye icon_input" id="list" onClick={() => showPassword()}></span> */}
                                                </label>
                                                <input type="password" id="password" name="password" placeholder="Password" value={password} onChange={handleInputChange} />
                                                {isValid && (
                                                    <div style={{ color: "white" }}>White or empty space is not allowed.</div>
                                                )}
                                            </fieldset>

                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} className="col-xs-12">
                                        <fieldset>
                                            <label className="mb-2">Referral Code</label>
                                            <input type="text" name="referralcode" placeholder="Referral Code" value={referralcode} onChange={handleInputChange} />
                                        </fieldset>

                                    </Col>
                                    <Col lg={6} md={6} sm={6} className="col-xs-12">
                                        <small>{/* Google Captcha here.. */}</small>
                                        <br />

                                    </Col>

                                </Row>
                                <button className="tf-button btn-effect" type="button" onClick={() => handleRegister(username, number, email, otp, password, referralcode)}>
                                    <span className="boder-fade"></span>
                                    <span className="effect">Register</span>
                                </button>
                            </form>
                            <Link to="/login" className="forgot-password text-center d-block float-none">Already have an account</Link>
                            <div className="clearfix"></div>
                        </div>
                    </Col>
                    <Col lg={2} md={2} sm={12} className="col-xs-12"></Col>
                </Row>
            </div>
        </section>
    )
}

export default Register;