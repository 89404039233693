import React, { useEffect, useState } from "react";
import HomeService from "../api/services/HomeService";
import { alertErrorMessage, alertSuccessMessage } from "../CustomAlertMessage";
import { Sidebar } from './Sidebar';
import moment from "moment";
import AuthService from "../api/services/AuthService";

export const Refferal = () => {
    const [referalList, setReferalList] = useState([]);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');
    // const history = useHistory();

    useEffect(() => {
        handleReferalData();
        const email = localStorage.getItem("emailId");
        setEmail(email)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReferalData = async () => {
        await HomeService.getReferalData().then(async result => {
            console.log(result.data, 'getReferalData');
            if (result) {
                try {
                    setReferalList(result.data);
                } catch (error) {
                    //alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };
    const handleOtp = async (email) => {
        // LoaderHelper.loaderStatus(true);
        await AuthService.otpVerify(email,'Login').then(async result => {
            if (result.message === "OTP Sent.") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });

    }
    const handleSubmit = async (amount,otp,address) => {
        // LoaderHelper.loaderStatus(true);
        await HomeService.withdrawReferNfs(amount,otp,address).then(async result => {
            //console.log(result, 'loginD');
            if (result.message == "Transfer Request submitted successfully") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    // alertErrorMessage(error);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });

    }
    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "amount":
                setAmount(event.target.value);
                console.log(event.target.value);
                break;
            case "address":
                setAddress(event.target.value);
                console.log(event.target.value);
                break;
            case "otp":
                setOtp(event.target.value);
                break;
            
           
        }
    }
    return (
        <>
            <Sidebar />
            <div className="container mt-5" id="main">
                <div className="row">
                    <h5 className='h8 mx-5'>Referral</h5>
                </div>

                <div className="row mx-3 mt-3">
                    <ul className="nav nav-pills mx-3 mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link py-3 h8 m-0 px-4 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                type="button" role="tab" aria-controls="pills-home" aria-selected="true">Referral Earned
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link h8 py-3 px-4" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Withdraw 
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <table className='nfs-table table'>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Referred User</th>
                                        <th>Referred User Email ID</th>
                                        <th>NFS Bonus</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {referalList.length > 0 ?
                                        referalList.map((item, index) =>
                                            <tr key={index} style={{color: "white"}}>
                                                <td>{index + 1}</td>
                                                <td>{item?.uData[0]?.username}</td>
                                                <td>{item?.uData[0]?.email}</td>
                                                <td>{item?.bal}</td>
                                                <td>{moment(item?.uData[0]?.createdAt).format('MMM DD YYYY h:mm A')}</td>
                                            </tr>
                                        ) : <tr>
                                            <td colspan="5"><h6 className="h8">No Referral Earned</h6></td>
                                        </tr>}

                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="row my-3">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-6">
                              <h5 className='text-center'>Referral Wallet</h5>
                                <div className=" mt-4 box-text corner-box active">
                                    <form className="form-contact">
                                        <p className="mb-2">Withdraw Token</p>
                                        <hr/>
                                        <fieldset>
                                            <p className="mb-2">Earned NFS</p>
                                            <input type="text" name="amount" value={amount} onChange={handleInputChange}  placeholder="" />
                                        </fieldset>
                                        {/* <p className="mb-2">USDT  Balance - 0</p>
                                        <div className='nfs-conv mb-3'>
                                            <p>1 NFS = 0.025</p>
                                        </div>  */}
                                        <fieldset>
                                            <p className="mb-2">Enter Wallet Address</p>
                                            <input type="text" name="address" value={address} onChange={handleInputChange} placeholder="Enter Your Address" />
                                        </fieldset>
                                        <div className="otp_group"><fieldset><input type="text" className="mb-2" name="otp" value={otp} onChange={handleInputChange}/><button type="button" id="getotp" onClick={() => handleOtp(email)}>GET OTP</button></fieldset></div>
                                        <fieldset>                                          
                                            <button className="tf-button btn-effect update-btn float-end" type="button">
                                                <span className="boder-fade"></span>
                                                <span className="effect" onClick={() => handleSubmit(amount, otp, address)}>Withdraw</span>
                                            </button>
                                        </fieldset>                                         
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}