import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import './style.css';
import { Container, Row, Col, Button } from 'reactstrap';
import AuthService from "../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../CustomAlertMessage";
import { useNavigate } from "react-router-dom";
import loginimg from "../../assets/images/login_bg.jpg";
// import { faBreadSlice } from "@fortawesome/free-solid-svg-icons";
// import LoaderHelper from "../Loading/LoaderHelper";

const Reset = () => {

    const history = useNavigate();
    const [password, setPassword] = useState('');
    const [conPassword, setConPassword] = useState('');
    const id = window.location.href.split("=")[1];
    console.log(id, "id");

    useEffect(() => {
        handleVerifyHex(id);
    }, [])

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "password":
                setPassword(event.target.value);
                console.log(event.target.value);
                break;
            case "Conpassword":
                setConPassword(event.target.value);
                console.log(event.target.value);
                break;
            default:
                break;
        }
    }

    const handleVerifyHex = async(id) => {
        await AuthService.verifyId(id).then(async result => {
            if (result.status === !true) {
            try{
                history("/");
            } catch (error) {
                // LoaderHelper.loaderStatus(false);
                alertErrorMessage(error);
                console.log('error', `${error}`);
            }
        }
        })
    }


    const handleReset = async (email, conPassword, id) => {
        // LoaderHelper.loaderStatus(true);
        await AuthService.resetPassword(email, conPassword, id).then(async result => {
            if (result.message === "Password changed successfully") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                    history("/login");
                    // window.location.reload(); 

                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);

            }
        });
    }


    return (

        <section className="page_sec tf-section tf-contact">
            <img className="login_bg" />
            <div className="container login-page" >
                <Row className="align-items-center no-gutter">

                    <Col lg={6} md={6} sm={12} className="col-xs-12 m-auto">
                        <div className="page-title p-4" >
                                <p className="your-session">Enter New Password</p>
                                <form action="" className="form-contact" id="mLoginForm">
                                    <fieldset className="my-4">
                                        <label className="mb-2">Enter Password</label>
                                        <input type="password" name="password" id="password" placeholder="Enter Password" value={password} onChange={handleInputChange} />
                                    </fieldset>

                                    <fieldset className="my-4">
                                        <label className="mb-2">Confirm Password</label>
                                        <input type="password" id="Conpassword" name="Conpassword" placeholder="Enter Confirm Password" value={conPassword} onChange={handleInputChange} />
                                    </fieldset>
                                    <button className="tf-button btn-effect" type="button" onClick={() => handleReset(password, conPassword, id)}><span className="boder-fade"></span><span className="effect">Reset Forgot</span>
                                    </button>
                                </form>

                                <Link to="/login" className="forgot-password">Already Have Account</Link>
                                <Link to="/register" className="account-link">Create New Account </Link>
                                <div className="clearfix"></div>
                        </div>
                    </Col>
                </Row >
            </div >
        </section >
    )
}

export default Reset;