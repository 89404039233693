import React, { useEffect, useState } from "react";
import HomeService from "../api/services/HomeService";
import { alertErrorMessage } from "../CustomAlertMessage";
import { Sidebar } from './Sidebar';
import moment from "moment";

export const Transaction = () => {
    const [transactionsList, setTransactionsList] = useState([]);
    const [blmTrans, setBlmTrans] = useState([]);
    const [usdtTrans, setUsdtTrans] = useState([]);
    // const history = useHistory();

    useEffect(() => {
        handleTransaction();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTransaction = async () => {
        await HomeService.getTransactions().then(async result => {
            //console.log(result.data, 'getTransactions');
            if (result.success) {
                try {
                    setTransactionsList(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                    //console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    useEffect(() => {
        if (transactionsList && Object.keys(transactionsList).length > 0) {
            let data = transactionsList.filter(item => item?.coinSymbol === 'NFS');
            setBlmTrans(data);
        }
        let data = transactionsList.filter(item => item?.coinSymbol === 'USDT');
        setUsdtTrans(data);

        console.log(transactionsList, 'lastJsonMessagerathore');
    }, [transactionsList]);
    return (
        <>
            <Sidebar />
            <div className="container mt-5" id="main">
                <div className="row">
                    <h5 className='mx-4'>Transaction</h5>
                </div>

                <div className="row mx-3 mt-3">
                    <ul className="nav nav-pills mx-3 mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link h8 py-3 px-4 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                type="button" role="tab" aria-controls="pills-home" aria-selected="true">NFS Token</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link h8 py-3 px-4" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">USDT</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active nfs-table-wrap" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <table className='nfs-table table'>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Amount</th>
                                        <th>Transaction Type</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {blmTrans.length > 0 ?
                                        blmTrans.map((item, index) =>
                                            <tr key={index} style={{color: "white"}}>
                                                <td>{index + 1}</td>
                                                <td>{item?.quantity}</td>
                                                <td>{item?.transType}</td>
                                                <td>{item?.status}</td>
                                                <td>{moment(item?.createdAt).format('MMM DD YYYY h:mm A')}</td>
                                            </tr>
                                        ) : <tr>
                                            <p className="status-text">No Transaction Found!</p>
                                        </tr>}

                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <table className='nfs-table table'>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Amount</th>
                                        <th>Transaction ID</th>
                                        <th>Transaction Type</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usdtTrans.length > 0 ?
                                        usdtTrans.map((item, index) =>
                                            <tr key={index} style={{color: "white"}}>
                                                <td>{index + 1}</td>
                                                <td>{item?.amount}</td>
                                                <td>{item?.txhash}</td>
                                                <td>{item?.transType}</td>
                                                <td>{item?.status}</td>
                                                <td>{moment(item?.createdAt).format('MMM DD YYYY h:mm A')}</td>
                                            </tr>
                                        ) : <tr>
                                            <p className="status-text">No Transaction Found!</p>
                                        </tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}