import React, { useState, useEffect } from "react";
import HomeService from "../api/services/HomeService";
import { alertErrorMessage, alertSuccessMesssage } from "../CustomAlertMessage";
import { Sidebar } from './Sidebar';

export default function Wallet() {
  const [usdtAddrss, setUsdtAddrss] = useState("");
  const [trxAddress, setTrxAddress] = useState("");

  useEffect(() => {
    handleAddress();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleAddress = async () => {
    await HomeService.getAddress().then(async result => {
      console.log(result.data, 'getAddress');
      if (result.success) {
        try {
          setTrxAddress(result.data.trxAddr);
          setUsdtAddrss(result.data.usdAddr);
        } catch (error) {
          alertErrorMessage(error);
          //console.log('error', `${error}`);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };


  const handleCreatedAddr = async (usdtAddrss, trxAddress) => {
    await HomeService.getCreatedAddr(usdtAddrss, trxAddress).then(async result => {
      console.log(result.data, 'CreatedAddr');
      if (result.success) {
        try {
          setTrxAddress(result.data.trxAddr);
          setUsdtAddrss(result.data.usdAddr);
          //alertSuccessMesssage(result.message);
        } catch (error) {
          alertErrorMessage(error);
          //console.log('error', `${error}`);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };
  return (
    <>
      <Sidebar />
      <div className="container" id="main">
        <div className="row mt-5">
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
          </div>
          <div className="col-md-6">
            <h5 className='text-center'>Deposit USDT</h5>

            <div className=" mt-4 box-text corner-box active text-center">
              <ul className="nav nav-pills my-4 d-flex justify-content-center" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <div className="tf-button-st2 dashboard-btn btn-effect" type="button">
                    <span className="effect">TRC 20</span>
                </div>
                </li>
              </ul>

              <div className="tab-content" id="pills-tab">
                <div
                  className="tab-pane fade show active"
                  id="pills-home-tab"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  {trxAddress == "" ? <div className="genrate_sec" id="asdf">
                    <button className="gen_btn btn btn-primary" onClick={() => handleCreatedAddr(usdtAddrss, trxAddress)}> <i className="fa fa-rotate"></i> Generate Address </button>
                  </div> : <div className="qr_code qr_code_block" id="qwert">
                    <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${trxAddress}&choe=UTF-8`} className="img-fluid" />

                    <div className="row mt-4">
                      <div className="qr_content pd-0" >
                        <div className="otp_group copy_input">
                          <button className="deposit-copy-btn" onClick={() => { navigator.clipboard.writeText(`${trxAddress}`) }}>Copy</button>
                          <input type="text" readonly="" className="form-control mr-b0" id="referral_link" value={trxAddress} />
                        </div>
                      </div>
                      <ul className="disclaimer text-start mt-3 mr-t20">
                        <label>Disclaimer</label>
                        <li>• Minimum deposit of 100 USDT, deposit below that cannot be recovered.</li>
                        <li>• Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.</li>
                        <li>• This is  TRC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.</li>
                      </ul>
                    </div>
                  </div>
                  }
                </div>
                <div className="tab-pane fade" id="pills-profile-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                  {usdtAddrss == "" ? <div className="genrate_sec" id="asdf">
                    <button className="gen_btn btn" onClick={() => handleCreatedAddr(usdtAddrss, trxAddress)}> <i className="fa fa-rotate"></i> Generate Address </button>
                  </div> : <div className="qr_code qr_code_block" id="qwert">
                    <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${usdtAddrss}&choe=UTF-8`} className="img-fluid" />
                    <div className="qr_content pd-0" >
                      <div className="otp_group copy_input">
                        <button id="getotp" onClick={() => { navigator.clipboard.writeText(`https://ieo.bloomex.io/register?reffcode=${usdtAddrss}`) }}>Copy</button>
                        <input type="text" readonly="" className="form-control mr-b0" id="referral_link" value={usdtAddrss} />
                      </div>
                    </div>
                    <ul className="disclaimer mr-t20">
                      <label>Disclaimer</label>
                      <li>• Minimum deposit of 100 USDT, deposit below that cannot be recovered.</li>
                      <li>• Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.</li>
                      <li>• This is BEP20 deposit address type. Transferring to an unsupported network could result in loss of deposit.</li>
                    </ul>
                  </div>}
                </div>
              </div>
              {/* <!-- Tabs content --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
