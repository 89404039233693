import React, {useState} from 'react';
import AuthService from "../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../CustomAlertMessage";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from 'reactstrap';
import { event } from 'jquery';

export default function Forget() {
    const history = useNavigate();
    const [email, setEmail] = useState('');

    const handleForgot = async (email) => {
        // LoaderHelper.loaderStatus(true);
        await AuthService.forgotPassword(email).then(async result => {
            if (result.message === "Reset password link sent to your email!!") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                    history("/");
                    // window.location.reload(); 

                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);

            }
        });
    }

  return (
    <>
         <section className="page_sec tf-section tf-contact" >
            <img className="login_bg" />
            <div className="container login-page" >
                <Row className="align-items-center no-gutter">

                    <Col lg={6} md={6} sm={12} className="col-xs-12 m-auto">
                        <div className="page-title p-4" >
                                <p className="your-session">Forgot Password</p>
                                <form action="" className="form-contact" id="mLoginForm">
                                    <fieldset className="my-4">
                                        <label className="mb-2">Email</label>
                                        <input type="email" name="email" id="email" placeholder="Enter Email Address..."  value={email} onChange={(event) => setEmail(event.target.value)}/>
                                    </fieldset>
                                    <button className="tf-button btn-effect" type="button" onClick={() => handleForgot(email)}><span className="boder-fade"></span><span className="effect">Get Password</span>
                                    </button>
                                </form>

                                <Link to="/login" className="forgot-password text-center d-block float-none" onClick={() => history("/login")}>Already have an account</Link>
                                <div className="clearfix"></div>
                        </div>
                    </Col>
                </Row >
            </div >
        </section >
    </>
  )
}
