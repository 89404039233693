import React from 'react'
import background from '../images/background.jpg'

export const Upcomingdrop = () => {

  const questionMarkImg = {
    height: '12rem',
  }
  const swiperWrapper = {
    width: '35%',
  }
  const backgroundImg = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: '100%',
  };
  return (
    <>

        <section className="page-title" style={backgroundImg}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumbs">
                  <h3>Questionmark Genesis</h3>
                </div>
              </div>
            </div>
          </div>
          <div className='row question-mark-slider'>
            {/* Flickity HTML init */}
            <marquee className="d-flex" style={{ paddingTop: '5rem' }} direction="right" behavior="alternate">
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/11.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/12.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/13.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/14.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/15.jpg"} style={questionMarkImg} />

            </marquee>

            <marquee className="mt-5 d-flex" direction="left" behavior="alternate">
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/16.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/17.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/18.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/19.jpg"} style={questionMarkImg} />
              <img className="img-fluid mx-3" src={process.env.PUBLIC_URL + "/assets/images/question_mark/20.jpg"} style={questionMarkImg} />
            </marquee>

          </div>
        </section>

        <section className="tf-section tf-video pb-mobie">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-about m-b51 mobie-40">
                  <div className="tf-title pd14 aos-init aos-animate">
                    <h6 className="title Q-subheading">
                      What’s so special about Questionmark Genesis NFT Collection?
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="box-text corner-box active">
                    <p className="text-justify">
                      After doing research we become clear that people are interested i=-09
                      becoming part of the crypto and blockchain industry and they loved the NFTs and
                      Metaverse and want to be part of it, and enjoy the returns by being a part of a
                      decentralized space. NFTSpace is announcing the drop of 1111 unique Questionmark Genesis
                      NFTs, your power into the NFTSpace.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tf-section tf-team bg-st1 mt-5">
          <div className="container">
            <div className="row">
              <div className="content-about mobie-40">
                <div className="tf-title st2">
                  <h4 className="title text-center">Why Questionmark NFT?</h4>
                </div>
              </div>
              <div className="col-md-1 mt-3"></div>
              <div className="col-md-12 mt-3">
                <div className="wrap-about">
                  <div className="box-text corner-box active">
                    <p className="text-justify">
                      Getting into the Questionmark game is not an easy way to go. It requires lots of
                      discussions lot of research and lots of technical reading, it’s like fighting
                      against the technology to make the technology win That’s why, for the past several
                      months, NFTSpace is working hard at building a Questionmark Genesis NFT collection
                      that grants the most valuable power to you in our new Web3 identities.
                    </p>
                    <p className="text-justify mt-1">A huge community is waiting to enter the crypto and NFT
                      market, due to a lack of knowlede they got scammed. We as a community are going to
                      use the power of knowledge and the power of questions that leads to more awareness
                      and help the community to enter the NFT market and enjoy the growth and be a part of
                      technology change.
                      "
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="content-about mobie-40">
                <div className="tf-title st2">
                  <h4 className="title text-center">What is Questionmark NFT?</h4>
                </div>
              </div>
              <div className="col-md-1 mt-3"></div>
              <div className="col-md-12 mt-3">
                <div className="wrap-about">
                  <div className="box-text corner-box active">
                    {/* <!-- <div className="h7">Unique, 1/1 generated</div> --> */}
                    <p className="text-justify">
                      We have taken our first step, which is to onboard as many people as possible into
                      Web3. Whether through asking questions, educational content or through NFTs.
                      Building a community-based platform that is open for all those who have the crypto
                      and blockchain industry knowledge and for those who want
                      to gain the right knowledge. Mint price will be disclosed on our Twitter handle
                      @questiongenesis at the same time we guarantee you that you will gain knowledge and
                      powers that will be worth more than the mint investment. On the other side, all the
                      star performers on our platform will be given a chance to get the power to mint it
                      before the mint, the project sale is based on whitelisting procedure and our
                      pre-sale will also have the benefit that
                      you avoid gas fees and mint at the lowest price possible.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="content-about mobie-40">
                <div className="tf-title st2">
                  <h4 className="title text-center">Who is behind Questionmark NFT?</h4>
                </div>
              </div>
              <div className="col-md-1 mt-3"></div>
              <div className="col-md-12 mt-3">
                <div className="wrap-about">
                  <div className="box-text corner-box active">
                    <h6 className="title Q-subheading text-center">The NFTSpace everything you need to know?
                    </h6>
                    <p className="text-justify">
                      We are creating a community-based ecosystem, this ecosystem aim is to provide
                      holders of Questionmark Genesis NFT the access to raise questions and share
                      knowledge, fun, NFT insights, powers and tickets across the Web3 space
                      Questionmark genesis NFT holders will have access to NFT space where they can claim
                      their powers. Powers will be offered in every drop of NFTSpace or through NFTSpace.
                      just remember it is only applicable to the holders.
                      As we grow, we will continue to extend opportunities to our community so we all grow
                      together. community rewards will be announced from time to time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tf-section tf-about pb-mobie">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="content-about mobie-40">
                  <div className="tf-title st2">
                    {/* <!-- <p className="h8 sub-title">About us</p> --> */}
                    <h4 className="title">NFTSpace is your access to </h4>
                  </div>
                  <p>Everything You Need to Know? NFTSpace is a community-based ecosystem, it’s your access to collect, create, stake, lend, buy, sell and earn. NFTSpace is all about sharing knowledge, fun, insightful information mainly raise questions to earn nft powers and tickets across the Web3 space:</p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="wrap-about p-l70">
                  <div className="box-text corner-box active">
                    <div className="h7">NFTSpace Features</div>
                    <ul className="nft-features-list">
                      <li> NFT Creator</li>
                      <li> NFT Marketplace </li>
                      <li> NFT staking </li>
                      <li> NFT play to earn </li>
                      <li> Metaverse </li>
                      <li> Referral Programme</li>
                    </ul>
                  </div>
                  <div className="box-text corner-box">
                    <div className="h7">NFTSpace Project Higlights</div>
                    <ul className="nft-features-list">
                      <li> Presale access to NFTs and additional drops</li>
                      <li> Ticket to whitelist wallets and projects. </li>
                      <li> Educational programs </li>
                      <li> launchpads </li>
                      <li> innovation Zone </li>
                      <li> Discounts to crypto platforms </li>
                      <li> Access to communities</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tf-section tf-team bg-st1 mt-5">
          <div className="container">
            <div className="row">
              <div className="content-about mobie-40 aos-init aos-animate">
                <div className="tf-title st2">
                  <h4 className="title text-center ">What is the power of Questionmark NFT?</h4>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">01</div>
                  <div className="h7 Q-subheading text-center mt-2">Free NFT Drop</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">02</div>
                  <div className="h7 Q-subheading text-center mt-2">NFTSpace Drops</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">03</div>
                  <div className="h7 Q-subheading text-center mt-2">New NFT Drops</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">04</div>
                  <div className="h7 Q-subheading text-center mt-3">Whitelist yourself</div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">05</div>
                  <div className="h7 Q-subheading text-center mt-2">Whitelist others</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">06</div>
                  <div className="h7 Q-subheading text-center mt-3">NFTs pre sale access</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">07</div>
                  <div className="h7 Q-subheading text-center mt-2">NFS token sale pre access</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 aos-init aos-animate">
                <div className="box-text st2 corner-box my-3">
                  <div className="number">08</div>
                  <div className="h7 Q-subheading text-center mt-2">NFTSpace Metaverse Avatars </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <p className="q-p text-center" style={{ fontSize: '2rem', lineHeight: '3rem' }}>Follow us on Twitter for our latest questions and participate to become a part of our first drop of 1111 “Question Mark Genesis NFTs."</p>
              </div>
            </div>
          </div>
        </section>

        <section className="tf-section tf-roadmap style2">
          <div className="overlay"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="tf-title m-b40">
                  {/*<p className="h8 sub-title">ROADMAP</p>  */}
                  <h4 className="title">Join our Community</h4>
                </div>
              </div>
              <div className="col-md-12">
                <div className="roadmap style2 style3">
                  <div className="swiper-container swiper sl-roadmap2 btn-style">
                    <div className="swiper-wrapper" style={swiperWrapper}>
                      <div className="swiper-slide">
                        <div className="box">
                          <div className="rm-box style">
                            <div className="corner-box">
                              <h5>Join our discord (its free!) and its private thinking why?</h5>
                              <p>
                                Our community lives on Discord - its where we are sharing knowledge and ideas about how we are going to stop a project before it spams you.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box active">
                          <div className="rm-box style">
                            <div className="corner-box active">
                              <h5>Collect a Questionmark Genesis NFT</h5>
                              <p>
                                1111 unique question mark genesis NFTs drop is on your way.
                                The Questionmark Genesis NFT will give you access to powers at NFTSpace.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box">
                          <div className="rm-box style">
                            <div className="corner-box">
                              <h5>Contribute to  Question Mark NFT & get rewarded!</h5>
                              <p>Submit spam project reports and content, video content, smart contract flows, host a podcast or surprise us with your questions! want to become a contributer <span> <a href="#" style={{ color: "#14C2A3" }}>Contact us</a></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}
