import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
import { useRef } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import metamask from "../images/metamask.png";
// import coinbase from '../images/coinbase.png'

export const Header = () => {
  const { pathname } = useLocation();
  const myRef = useRef(null);

  useEffect(() => {
    console.log("first");
  }, [pathname]);

  return (
    <>
      <Navbar expand="lg">
        <Navbar.Brand>
          <Link to="/">
            {/* <img className="card-img-top img-fluid logo" src={logo} />
             */}
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                color: "#14C2A3",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              NFS Token
            </h1>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" ref={myRef}>
          <Nav className="ms-auto">
            {/* <Nav>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </Nav>
            <NavDropdown title="Drops" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/live_drop">Live Drop</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/upcoming_drop">Upcoming Drop</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav>
              <Link to="/coming_soon" className="nav-link">
                Coming Soon
              </Link>
            </Nav> */}
            {/* <NavDropdown title="Help" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/contact">Contact Us</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/faq">Faq</Link>
              </NavDropdown.Item>
            </NavDropdown> */}
            <Link
              to="/login"
              className="tf-button btn-effect"
              id="connect-wallet"
            >
              <span className="boder-fade"></span>
              <span className="effect">Buy Nfs</span>
            </Link>
            {/* <button
              className="tf-button btn-effect"
              id="connect-wallet"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <span className="boder-fade"></span>
              <span className="effect">Connect Wallet</span>
            </button> */}
            <a className="tf-button btn-effect" href="#" id="discord-btn">
              <span className="boder-fade"></span>
              <span className="effect">Discord</span>
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="title">Select wallet to connect</h1>
                  <p className="text">
                    You need a Polygon wallet to access the dashboard
                  </p>
                </div>
                <div className="col-md-12 col-6 d-flex justify-content-center">
                  <Link to="">
                    <div className="wallet-wraper">
                      <div className="img-wraper">
                        <img src={metamask} className="img-fluid" />
                      </div>
                      <h3 className="walltet-title">MetaMask</h3>
                    </div>
                  </Link>
                </div>
                {/* <div className="col-md-6 col-6 d-flex justify-content-center">
                            <Link to="">
                                <div className="wallet-wraper">
                                    <div className='img-wraper'>
                                        <img src={coinbase} className="img-fluid"/>
                                    </div>
                                    <h3 className='walltet-title'>Coinbase</h3>
                                </div>
                            </Link>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
