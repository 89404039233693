import "./App.css";
import "./responsive.css";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Home } from "./component/Home";
import { Contact } from "./component/Contact";
import { Livedrop } from "./component/Live_drop";
import { Upcomingdrop } from "./component/Upcoming_drop";
import { Comingsoon } from "./component/Coming_soon";
import { Slider } from "./component/Slider";
import { Faq } from "./component/Faq";
import Index from "./dashbord/Index";
import Myaccount from "./dashbord/Myaccount";
import { Transaction } from "./dashbord/Transaction";
import { Refferal } from "./dashbord/Refferal";
import Wallet from "./dashbord/Wallet";
import Deposit from "./dashbord/Deposit";
import Login from "./component/Login";
import Forget from "./component/Forget";
import Register from "./component/Register";
import Reset from "./component/Reset";
import { useEffect } from "react";
import Withdraw from "./dashbord/withdraw";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const MainWrapper = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainWrapper />}>
          <Route index element={<Login />} />
          <Route path="live_drop" element={<Livedrop />} />
          <Route path="slider" element={<Slider />} />
          <Route path="faq" element={<Faq />} />
          <Route path="upcoming_drop" element={<Upcomingdrop />} />
          <Route path="coming_soon" element={<Comingsoon />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="forgetpassword" element={<Forget />} />
          <Route path="resetpassword" element={<Reset />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="/dashboard" element={<Index />} />
        <Route path="/myaccount" element={<Myaccount />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/refferal" element={<Refferal />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/token-withdraw" element={<Withdraw />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
