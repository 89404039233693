// const appUrl = "http://localhost:3004";
// const appUrl = 'http://167.99.197.180:3004';
// const appUrl = 'https://api.nftspace.gallery';
const appUrl = "https://backend.nfstoken.net";

export const ApiConfig = {
  login: "login",
  register: "signup",
  otpVerify: "getcode",
  getBalance: "getUserBal",
  getReferalData: "getreflist",
  getPrice: "getprice",
  getTransactions: "gettrans",
  getBuyCoin: "depositusdt",
  getAccountDetails: "getProfile",
  forgotpassword: "forgotpass",
  getUserSelfie: "uploadimage",
  getUserDetails: "updatedetails",
  getUsdtBal: "getusdtbal",
  getNfsBal: "getavailable",
  withdrawNfs: "tearnnfs",
  withdrawReferNfs: "trefernfs",
  getAddress: "deposit",
  getTokenSold: "createsecure/gettot",
  resetPassword: "changeFpass",
  verifyId: "verifyHex",
  getCreatedAddr: "createwall",

  baseUrl: `${appUrl}/auth/`,
  appUrl: `${appUrl}/`,
  baseWallet: `${appUrl}/wallet/`,
  // webSocketUrl: 'ws://18.212.25.17:3002',
};
