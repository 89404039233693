import React, {useState, useEffect} from "react";
import HomeService from "../api/services/HomeService";
import { alertErrorMessage, alertSuccessMessage } from "../CustomAlertMessage";
import { Sidebar } from './Sidebar';

export default function Myaccount() {
    const [details, setDetails] = useState([]);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [selfie, setSelfie] = useState('');
    // const history = useHistory();

    useEffect(() => {
        handleAccountDetails();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAccountDetails = async () => {
        await HomeService.getAccountDetails().then(async result => {
        console.log(result.message, 'getAccountDetails');
        if (Object.keys(result).length > 0) {
            try {
                setDetails(result.message);
                setEmail(result.message.email);
                setFirstName(result.message.first_name);
                setLastName(result.message.last_name);
                setLastName(result.message.last_name);
                setSelfie(result.message.profileImage);
            } catch (error) {
            //alertErrorMessage(error);
            console.log('error', `${error}`);
            }
        } else {
            alertErrorMessage(result.message);
        }
        });
    };

    const handleUserDetails = async (firstName, lastName, email, country) => {
        await HomeService.getUserDetails(firstName, lastName, email, country).then(async result => {
            console.log(result.message, 'getAccountDetails');
            if (result.message == "Data Updated Successfully!!") {
                try {
                    alertSuccessMessage(result.message);
                    handleAccountDetails();
                } catch (error) {
                //alertErrorMessage(error);
                console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    const handleChangeSelfie = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        console.log(event.target.files[0], 'event.target.files[0]');
        //const imgata = URL.createObjectURL(fileUploaded);
        setSelfie(fileUploaded);
        handleUserSelfie(fileUploaded);
        console.log(selfie);
    }

    const handleUserSelfie = async (selfie) => {
        var formData = new FormData();
        formData.append('selfie', selfie);
        await HomeService.getUserSelfie(formData).then(async result => {
        console.log(result, 'getUserSelfie');
        if (result.status) {
            try {
                alertSuccessMessage(result.message);
            } catch (error) {
            //alertErrorMessage(error);
            console.log('error', `${error}`);
            }
        } else {
            alertErrorMessage(result.message);
        }
        });
    };

    const handleInputchange = (event) => {
        switch(event.target.name) {
            case "firstName":
                setFirstName(event.target.value);
                break;

            case "lastName":
                setLastName(event.target.value);
                break;

            case "email":
                setEmail(event.target.value);
                break;

            case "country":
                setCountry(event.target.value);
                break;

            default:
                break;
        }
    }

    return (
        <>
            <Sidebar />
            <div className="container" id="main">
                <div className="row py-5">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6">
                        <h5 className="text-center">My Account</h5>
                        <div className="box-text corner-box mt-4 active">
                            <p className='dashbord-subtitle'>Account Details</p>
                            <hr />
                            <form action="contact/contact-process.php" className="form-contact" id="contactform">
                                <fieldset>
                                    <input type="text" id="name" name="firstName" placeholder="Enter your first name" value={firstName} onChange={handleInputchange}  />
                                </fieldset>
                                <fieldset>
                                    <input type="text" id="name" name="lastName" placeholder="Enter your last name" value={lastName} onChange={handleInputchange} />
                                </fieldset>
                                <fieldset>
                                    <input type="email" name="email" id="email" placeholder="Email Address" value={email} />
                                </fieldset>
                                <fieldset>
                                    <input type="text" name="country" placeholder="Enter your Nationality" value={country}  onChange={handleInputchange} />
                                </fieldset>
                                <button className="tf-button btn-effect update-btn" type="button" onClick={() => handleUserDetails(firstName, lastName, email, country)}>
                                    <span className="boder-fade"></span>
                                    <span className="effect">Update Account</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
