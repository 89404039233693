import React from 'react';
import { Sidebar } from './Sidebar';
import  { useEffect, useState } from "react";
import HomeService from "../api/services/HomeService";
import { alertErrorMessage, alertSuccessMessage, alertSuccessMesssage } from "../CustomAlertMessage";
import AuthService from '../api/services/AuthService';

export default function Withdraw() {
    const [totalBal, setTotal] = useState(0);
    const [availableBal, setAvailable] = useState(0);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');

    useEffect(() => {
        handleAddress();
        const email = localStorage.getItem("emailId");
        setEmail(email)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleAddress = async () => {
        await HomeService.getNfsBal().then(async result => {
          console.log(result.data, 'getAddress');
          if (result.success) {
            try {
              setAvailable(result.data.available);
              setTotal(result.data.total);
            } catch (error) {
              alertErrorMessage(error);
              //console.log('error', `${error}`);
            }
          } else {
            // alertErrorMessage(result.message);
          }
        });
      };
      const handleInputChange = (event) => {
        switch (event.target.name) {
            case "amount":
                setAmount(event.target.value);
                console.log(event.target.value);
                break;
            case "address":
                setAddress(event.target.value);
                console.log(event.target.value);
                break;
            case "otp":
                setOtp(event.target.value);
                break;
            
           
        }
    }
      const handleOtp = async (email) => {
        // LoaderHelper.loaderStatus(true);
        await AuthService.otpVerify(email,'Login').then(async result => {
            if (result.message === "OTP Sent.") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });

    }
    const handleSubmit = async (amount,otp,address) => {
        // LoaderHelper.loaderStatus(true);
        await HomeService.withdrawNfs(amount,otp,address).then(async result => {
            //console.log(result, 'loginD');
            if (result.message == "Transfer Request submitted successfully") {
                try {
                    // LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result.message);
                } catch (error) {
                    // LoaderHelper.loaderStatus(false);
                    // alertErrorMessage(error);
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                // LoaderHelper.loaderStatus(false);
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });

    }
    return (

        <>
            <Sidebar />
           <section>
                <div className='container py-5'>
                    <div className='row'>
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <h5 className='text-center'>Token Withdraw</h5>
                            <div className=" mt-4 box-text corner-box active">
                                <form className="form-contact">
                                    {/* <p className="mb-2">Total NFS Balance</p>
                                    <hr/>
                                    <fieldset>
                                        <p className="mb-2">Earned NFS</p>
                                        <input type="text" name="nationality" id="nationality" placeholder="" />
                                    </fieldset> */}
                                    <p className="mb-2">Total NFS Balance - {totalBal}</p>
                                    <p className="mb-2">Available For Withdraw -  {availableBal}</p>
                                    <fieldset>

                                    <input type="text" name="amount" value={amount} onChange={handleInputChange} placeholder="Enter Amount" />
                                    </fieldset>

                                    <p className="mb-2">Wallet Address (NFS)</p>

                                    {/* <div className='nfs-conv mb-3'>
                                        <p>1 NFS = 0.025</p>
                                    </div>  */}
                                    <fieldset>
                                        {/* <p className="mb-2">USDT Address (Trc20)</p> */}
                                        <input type="text" name="address" value={address} onChange={handleInputChange} placeholder="Enter Your Address" />
                                    </fieldset>
                                    <fieldset>                                      
                                        <button className="tf-button btn-effect update-btn" type="button">
                                            <span className="boder-fade"></span>
                                            <span className="effect" onClick={() => handleOtp(email)}>Get OTP</span>
                                        </button>
                                    </fieldset>
                                    <div className="otp_group">
                                        <fieldset>
                                            <input type="email" name="otp" value={otp} onChange={handleInputChange} placeholder='Enter OTP'/>
                                        </fieldset>
                                    </div>
                                    <fieldset>                                      
                                        <button className="tf-button btn-effect update-btn" type="button">
                                            <span className="boder-fade"></span>
                                            <span className="effect" onClick={() => handleSubmit(amount, otp, address)}>Submit</span>
                                        </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
        </>
    );
}
