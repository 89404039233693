import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import HomeService from "../api/services/HomeService";
import { alertErrorMessage, alertSuccessMesssage } from "../CustomAlertMessage";

export default function Index() {
  const [balance, setbalance] = useState([]);
  const [price, setPrice] = useState([]);
  const [refUser, setRefUser] = useState("");
  const [tokenSold, setTokenSold] = useState("");
  const history = useNavigate();

  useEffect(() => {
    handleBalance();
    handlePrice();
    handleAccountDetails();
    handleTokenSold();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBalance = async () => {
    await HomeService.getBalance().then(async (result) => {
      console.log(result.data, "getBalance");
      if (result.msg == "done") {
        try {
          setbalance(result.bal);
        } catch (error) {
          alertErrorMessage(error);
          //console.log('error', `${error}`);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handlePrice = async () => {
    await HomeService.getPrice().then(async (result) => {
      console.log(result.data, "getPrice");
      if (result.success) {
        try {
          setPrice(result.resp);
        } catch (error) {
          alertErrorMessage(error);
          //console.log('error', `${error}`);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };

  const handleTokenSold = async () => {
    await HomeService.getTokenSold().then(async (result) => {
      console.log(result.message, "TokenSold");
      if (result.success) {
        try {
          setTokenSold(result.message);
        } catch (error) {
          alertErrorMessage(error);
          //console.log('error', `${error}`);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };

  const handleAccountDetails = async () => {
    await HomeService.getAccountDetails().then(async (result) => {
      console.log(result.message, "getAccountDetails");
      if (Object.keys(result).length > 0) {
        try {
          setRefUser(result.message.username);
        } catch (error) {
          //alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handleLogOut = () => {
    localStorage.clear();
    history("/");
  };

  function copyText() {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); // For mobile devices
    console.log("in copy ", copyText);
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.innerHTML);
  }

  return (
    <>
      <div className="main">
        <Sidebar />
        <div className="my-dashboard" id="main">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-5">
                {/* <h5 className="h7">Dashboard</h5> */}
                <div className="box-text corner-box active dashboard-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="nfs-head d-flex">
                        {/* <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/logo/favicon.png"
                          }
                          style={{ width: "4rem", objectFit: "contain" }}
                        /> */}
                        <h5 className="nfs-heading p-3">NFS Token</h5>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                      <div
                        className="tf-button-st2 dashboard-btn btn-effect"
                        type="button"
                      >
                        <Link to="/wallet" className="effect">
                          BUY NOW
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center py-3">
                        <div>
                          <p className="dashbord-subtitle">NFS Balance</p>
                          <h4>{balance?.total?.toFixed(2)}</h4>
                        </div>
                        <div
                          className="tf-button-st2 dashboard-btn btn-effect"
                          type="button"
                        >
                          <Link to="/token-withdraw" className="effect">
                            Withdraw
                          </Link>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                    <p className='dashbord-subtitle'>Total Value in USDT</p>
                    <h4>{(balance?.total * price)?.toFixed(2)}</h4>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                {/* <h6 className='h7 text-end '>Public sale {tokenSold}% Ongoing</h6> */}
                <div className="box-text corner-box tabl-mb-30 active dashboard-box">
                  <div className="row">
                    <div className="col-md-4">
                      {/* <img
                        className="img-fluid w-75"
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://nfstoken.net/register?reffcode=${refUser}`}
                      /> */}
                    </div>
                    <div className="col-md-8">
                      <h6 className="h8 text-end">
                        Total NFS Earned From Referral:
                      </h6>
                      <h1 className="balance-text float-end">{balance?.ref}</h1>
                    </div>
                    <hr />
                  </div>
                  <p className="text-white">Referral Link:</p>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="my-3 link-box">
                        <p
                          className="p-2 text-white"
                          value="https://nfstoken.net/register?reffcode=undefined"
                          id="myInput"
                        >
                          {`https://nfstoken.net/register?reffcode=${refUser}`}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button className="copy-btn mt-3" onClick={copyText}>
                        Copy
                      </button>
                    </div>
                  </div>
                  <p className="h8 text-white pt-2">
                    Earn 5% on every Referral !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
