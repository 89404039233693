import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Container, Row, Col, Button } from "reactstrap";
import AuthService from "../../api/services/AuthService";
import {
  alertSuccessMessage,
  alertErrorMessage,
} from "../../CustomAlertMessage";
import { useNavigate } from "react-router-dom";
import loginimg from "../../assets/images/login_bg.jpg";
// import { faBreadSlice } from "@fortawesome/free-solid-svg-icons";
// import LoaderHelper from "../Loading/LoaderHelper";

const Login = () => {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const showPassword = () => {
    let pass = document.querySelector('input[name="password"]');
    let icon = document.querySelector("#list");
    console.log(pass.type);
    if (pass.type === "password") {
      icon.classList.add("show");
      pass.type = "text";
    } else {
      icon.classList.remove("show");
      pass.type = "password";
    }
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "email":
        setEmail(event.target.value);
        console.log(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        console.log(event.target.value);
        break;
      // case "otp":
      //     setOtp(event.target.value);
      //     console.log(event.target.value);
      //     break;
      default:
        break;
    }
  };

  const handleLogin = async (email, password) => {
    // LoaderHelper.loaderStatus(true);
    await AuthService.login(email, password).then(async (result) => {
      if (result.message === "Login Success") {
        try {
          // LoaderHelper.loaderStatus(false);
          localStorage.setItem("token", result.authRes.token);
          localStorage.setItem("userId", result.authRes.userId);
          localStorage.setItem("emailId", result.authRes.emailId);
          alertSuccessMessage(result.message);
          history("/dashboard");
          // window.location.reload();
        } catch (error) {
          // LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        // LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  // const handleOtp = async (email) => {
  //     await AuthService.otpVerify(email).then(async result => {
  //       if (result.message === "Login Success") {
  //         try {
  //             alertSuccessMessage(result.message);
  //         } catch (error) {
  //             alertErrorMessage(error);
  //             alertErrorMessage(error);
  //           console.log('error', `${error}`);
  //         }
  //       } else {
  //         alertErrorMessage(result.message);
  //       }
  //     });

  //   }

  return (
    <section className="page_sec tf-section tf-contact">
      <img className="login_bg" />
      <div className="container login-page">
        <Row className="align-items-center no-gutter">
          <Col lg={6} md={6} sm={12} className="col-xs-12 m-auto">
            <div className="page-title p-4">
              <p className="your-session">Login in to NFS Token </p>
              <form action="" className="form-contact" id="mLoginForm">
                <fieldset className="my-4">
                  <label className="mb-2">Enter Email</label>
                  {/* <button type="button" id="getotp" onClick={() => handleOtp(email)}>GET OTP</button> */}
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address..."
                    value={email}
                    onChange={handleInputChange}
                  />
                </fieldset>

                <fieldset className="my-4">
                  <label className="mb-2">Enter Password</label>
                  {/* <span className="fa fa-eye icon_input" id="list" onClick={() => showPassword()}></span> */}
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleInputChange}
                  />
                </fieldset>
                <button
                  className="tf-button btn-effect"
                  type="button"
                  onClick={() => handleLogin(email, password)}
                >
                  <span className="boder-fade"></span>
                  <span className="effect">Login</span>
                </button>
              </form>

              <Link to="/forgetpassword" className="forgot-password">
                Forgot Password?
              </Link>
              <Link to="/register" className="account-link">
                Create New Account{" "}
              </Link>
              <div className="clearfix"></div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Login;
